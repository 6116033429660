@import '../../../../generalStyle';

$sideBarTitleColor: #ff6b76;
$tabPickerLinkRadius: 14px;

.draggableElm {
  display: flex;
  justify-content: center;
  height: 20px;

  svg {
    color: #c2c2c2;
  }
}

.headerTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 20px;
  height: 50px;
}

.groupTitle {
  @include title(20px, $sideBarTitleColor);
}

.goBackIcon {
  @include icon($sideBarTitleColor, 15px, 0px, -10px, relative);
}

.groupTitle {
  @include title(20px, $sideBarTitleColor);
}

.groupNameTitle {
  @include title(20px, $sideBarTitleColor, auto);
  left: -14px;
  position: relative;
  max-width: 60%;
}

.groupActionIcon {
  color: $sideBarTitleColor;
}

.groupAction {
  margin-left: 10px;
}

.searchIcon {
  @include icon($thridGrayColor, auto, auto, calc(100% - 50px));
}

.searchText {
  @include input-search();
  width: 100%;

  div {
    width: 100% !important;
  }
}

.closeIcon {
  @include icon($thridGrayColor, 15px, 9px, calc(100% - 30px));
}
