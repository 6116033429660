@import '../../../generalStyle';

.container {
  margin-top: auto;
  display: flex;
  z-index: 3;
  height: calc(100% - 110px);
  background-color: #fff;
  padding-bottom: 0px !important;
  flex-direction: column;
}

.collapseContainer {
  height: 40%;
}

.listContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.userItem {
  border-bottom: 1px solid $sixthGrayColor !important;
}

.groupModal {
  form {
    padding: 5px !important;
  }
}
