.logoLoading-massive {
  width: 4.57142857rem;
  height: 4.57142857rem;
}

.logoLoading-huge {
  width: 4.14285714rem;
  height: 4.14285714rem;
}

.logoLoading-big {
  width: 3.71428571rem;
  height: 3.71428571rem;
}

.logoLoading-large {
  width: 3.42857143rem;
  height: 3.42857143rem;
}

.logoLoading-medium {
  width: 2.28571429rem;
  height: 2.28571429rem;
}

.logoLoading-small {
  width: 1.71428571rem;
  height: 1.71428571rem;
}

.logoLoading-tiny {
  width: 1.14285714rem;
  height: 1.14285714rem;
}

.removeDefaultAnimation {
  padding: 0px !important;
}

.removeDefaultAnimation::before,
.removeDefaultAnimation::after {
  display: none;
}
