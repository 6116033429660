@import 'generalStyle';

.header {
  @include title(14px, $thridGrayColor);
  background-color: $primaryBackgroundColor !important;
}

.headerMarked {
  @include title(14px, $thridGrayColor);
  background-color: $primaryBackgroundColor !important;
  border-bottom: 0.2em solid $primaryGreenColor !important;
  color: $primaryGreenColor !important;
}

.emptyHeader {
  background-color: $primaryBackgroundColor !important;
  max-width: 312px;
  
  .projectContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 15px;
  }
}

.changeWeekButton {
  display: flex;
  margin-left: auto;
  padding: 0 5px;
}

.changeWeekButton:hover {
  cursor: pointer;
}

.changeNextWeek:hover {
  cursor: pointer;
}

.tableHeader {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1; 
}
