@import 'generalStyle';

.cardDetail {
  bottom: 16px !important;
  border-radius: 12px;
}

.available {
  font-size: 16px;
  font-weight: bold;
  margin-top: 4px;
  text-transform: uppercase;
}

.connected {
  color: $secondaryGreenColor;
}

.disconnected {
  color: $fifthGrayColor;
}

.arrowSize {
  font-size: 21px !important;
}

.cardDetail,
.leaflet-popup-content-wrapper {
  box-shadow: 0 1px 5px $thirdShadowColor;
}

.inactiveAttendance,
.activeAttendance {
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: -13px;
  right: -17px;
}

.inactiveAttendanceSpan,
.activeAttendanceSpan {
  position: absolute;
  right: -8px;
  bottom: 0px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  width: 31px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
