.container {
  justify-content: space-between;
  padding: 10px;

  .title {
    font-size: 1em;
    font-weight: bold;
    margin-top: 1em;
  }

  .categoryTime {
    display: flex;
    flex-direction: row;

    .category {
      width: 70%;
    }

    .time {
      width: 30%;
    }
  }
}
