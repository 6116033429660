@import 'generalStyle';

.timeField {
  color: $fourthGrayColor !important;
  font-size: medium !important;
  padding: 5px !important;
  border: unset !important;
}

.active {
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  border-color: $primaryInputFocusColor !important;
}
