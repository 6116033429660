.pageNotFoundcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin-top: 100px;
}

.message {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
