.container {
  display: grid;

  &::after {
    content: attr(data-replicated-value) ' ';
    white-space: pre-wrap;
    visibility: hidden;
  }
}

.textarea {
  resize: none !important;
  overflow: auto;
  height: unset !important;
  min-height: 8em !important;
}

.container > .textarea,
.container::after {
  border: 1px solid #000;
  padding: 0.5rem;
  font: inherit;
  grid-area: 1 / 1 / 2/ 2;
  max-height: 200px !important;
}

