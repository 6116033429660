@import 'generalStyle';

.singleButton {
  text-align: left !important;
  background-color: $defaultBackgroundColor !important;
}

.singleButton:hover {
  background-color: $primaryHoveredRowColor !important;
}

.popupActions {
  padding: 0px !important;
}
