.container {
  display: flex;
  z-index: 3;
  top: 0;
  height: calc(100% - 105px);
  background-color: #fff;
  padding-bottom: 0px !important;
  flex-direction: column;

  $sideBarTitleColor: #ff6b76;

  .listContainer {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

  }

  .logo {
    justify-content: center;
    align-content: center;
    
    img {
      width: 150px;
      margin: auto;
      margin-top: 5px;
    }
  }

  .loader {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }

  .accordion {
    padding-left: 8px !important;
  }

  .accordionText {
    font-weight: bold;
    color: $sideBarTitleColor;
  }

  .accordionIcon {
    color: $sideBarTitleColor;
  }
}
