@import 'generalStyle';

.userInfoContainer {
  display: flex;
  width: 100%;
  cursor: pointer;
  padding-top: 4px;
  padding-bottom: 4px;
  border-top: 1px solid $thirdShadowColor !important;
}

.userInfoContainer:hover {
  background-color: $primaryHoveredRowColor;
}

.userSelected {
  background-color: $primarySelectedRowColor !important;
  color: $primarySelectedRowTextColor !important;
}

.userImage {
  padding: 10px;
}

@mixin user-status {
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 14px;
  margin-top: 5px;
}

.available {
  @include user-status();
  color: $primaryGreenColor;
}

.notAvailable {
  @include user-status();
  color: $fifthGrayColor;
}

.userInfo {
  width: calc(100% - 90px);
  display: flex;
  flex-wrap: wrap;
  align-content: center;

  div {
    width: 100%;
  }
}

.userStatus {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.lastUserConnection {
  max-width: 60px;
  color: rgba(0, 0, 0, 0.301961);
  font-size: 12px;
  margin-top: 5px;
}

.actions {
  width: auto;
  display: flex;
  align-items: center;
}

.userActions {
  display: none !important;
}

.userInfoContainer:hover .userActions {
  display: block !important;
}
