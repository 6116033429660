@import '../../../generalStyle';

.profile {
  position: absolute;
  right: 0 !important;
  margin: 0 !important;
}

.checkInOut {
  background: #fff !important;
  border-radius: 5px 5px 0 0 !important;
  box-shadow: $primaryCardShadowDimensions $primaryShadowColor !important;
  border-bottom: 2px solid rgba(34, 36, 38, 0.1);
  color: #808080 !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 13px !important;
}

.ciCoButton {
  display: flex;
  align-items: center;
  vertical-align: middle;
  padding: 0.4rem 0.03rem 0.4rem 0 !important;
  gap: 7px;
}

.icon {
  margin-left: -2px !important;
}

.checkOutIcon {
  color: $fifthGrayColor;
}

.checkInIcon {
  color: $primaryGreenColor;
}

.checkOutLabel {
  margin-top: 3px;
  margin-right: -1px;
}

.checkInLabel {
  margin-top: 2px;
  margin-right: 5px;
}

.headerMessage {
  color: #808080 !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px !important;
  font-weight: bold;
}

.hourMessage {
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px !important;
  margin-right: 3px;
  color: #808080 !important;
}

.formatMessage {
  color: #808080 !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 12px !important;
  margin-right: 10px;
}

.inMessage {
  color: $primaryGreenColor !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px !important;
  font-weight: bold;
  letter-spacing: 1px;
  margin-right: 10px;
}

.outMessage {
  color: $fifthGrayColor !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px !important;
  font-weight: bold;
  letter-spacing: 1px;
  margin-right: 15px;
}

.placeMessage {
  color: #000c !important;
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px !important;
}

.bodyMessage {
  margin-top: 8px !important;
}
