@import 'generalStyle';

.paginationCmpt {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
}

.navigator {
  border: 0px !important;
  border-radius: 8px !important;
  box-shadow: $primaryCardShadowDimensions $primaryShadowColor !important;
}
