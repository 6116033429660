@mixin button() {
  background-color: $primaryBackGroundButtonColor !important;

  a {
    color: $primaryTextButtonColor !important;
  }

  &:hover {
    filter: contrast(1.2);
  }
}

@mixin secondaryButton() {
  background-color: $secondaryBackGroundButtonColor !important;

  a {
    color: $secondaryTextButtonColor !important;
  }

  &:hover {
    filter: contrast(1.2);
  }
}

@mixin title($font-size, $color, $width: auto) {
  color: $color !important;
  font-size: $font-size;
  font-weight: bold;
  width: $width;
}

@mixin input-search($top: 0px, $right: 0px, $width: 260px, $height: 38px) {
  text-align: center;
  top: $top;

  input {
    border: 1px solid $thridGrayColor !important;
    border-radius: 5px !important;
    position: relative;
    top: $top;
    right: $right;
    width: $width !important;
    background-color: transparent !important;
    font-family: 'Roboto', sans-serif !important;
    height: $height;
  }
}

@mixin icon($color, $font-size: 15px, $top: 0px, $left: 0px, $position: absolute) {
  font-size: $font-size !important;
  color: $color !important;
  position: $position;
  top: $top;
  left: $left;
  cursor: pointer;
}

@mixin shadow() {
  box-shadow: $primaryCardShadowDimensions $primaryShadowColor !important;
}
