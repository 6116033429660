@import 'generalStyle';

.submitButton {
  display: flex;
  justify-content: center;
  align-items: end;
}

.hint {
  margin-left: 4px;
  font-size: 10px;
  color: $thridGrayColor;
}

.timeInput {
  padding: 8px !important;
  height: 36px;
}
