.container {
  display: flex;
  z-index: 3;
  top: 0;
  height: calc(100% - 115px);
  background-color: #fff;
  padding-bottom: 0px !important;
  flex-direction: column;

  .listContainer {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .pagination {
    margin-top: 5px;
    padding: 0 10px;
  }

  .loader {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }

  .noUsers {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}
