@import '../../../generalStyle';

.container {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-right: 20px;
  margin-left: 20px;
  cursor: pointer;
  height: 44px;
  display: flex;

  .groupsIcon {
    @include icon($seventhGrayColor, 15px, 3px, 0px, relative);
    margin-right: 14px !important;
  }

  .groupText {
    @include title(14px, $primaryGrayColor);
    width: 100%;
  }

  .singleGroupActions {
    margin-left: auto;
  }
}

.selectedGroup {
  background-color: $primarySelectedRowColor !important;
  border-radius: 8px;
  
  .groupsIcon {
    color: $primarySelectedRowTextColor !important;
  }

  .singleGroupActions {
    color: $primarySelectedRowTextColor !important;
  }

  .groupText {
    color: $primarySelectedRowTextColor !important;
  }
}

.container:hover {
  background-color: $primaryHoveredRowColor;
  border-radius: 5px;
}
