@import 'generalStyle';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 20px;
  margin-right: 20px;
}

.header {
  position: relative;
  display: flex;
}

.time {
  color: #909090;
  font-size: 1em;
  background: #fff;
  font-weight: bold;
}

.leftArrow {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #909090;
  cursor: pointer;
}

.rightArrow {
  position: absolute;
  top: 50%;
  left: 95%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #909090;
  cursor: pointer;
}

.timeHeader {
  position: relative;
  text-align: center;
  width: 90%;
  left: 5%;
}

.attendanceList {
  display: flex;
  height: calc(100% - 400px);
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;
}

.attendanceItem {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 10px;
  gap: 10px;

  .timeCell {
    width: 35%;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    .time {
      width: 70% !important;
    }

    .timePeriod {
      color: $fourthGrayColor;
    }
  }

  .checkInCell {
    width: 15%;
    font-weight: bold;
    color: $primaryGreenColor;
  }

  .checkOutCell {
    width: 15%;
    font-weight: bold;
    color: #909090;
  }

  .placeCell {
    width: 38%;
  }

  .indicatorCell {
    display: flex;
    gap: 8px;
    width: 12%;
    align-items: center;

    .indicatorIcon {
      color: #8f8f8f;
    }
  }
}

.table {
  border: 0px !important;

  .row {
    .timeCell {
      width: 40%;
      text-align: right;
    }

    .time {
      color: $fourthGrayColor;
      margin-right: 5px;
      font-size: large;
    }

    .timePeriod {
      color: $fourthGrayColor;
    }

    .checkInCell {
      width: 20%;
      font-weight: bold;
      color: $primaryGreenColor;
    }

    .checkOutCell {
      width: 20%;
      font-weight: bold;
      color: #909090;
    }

    .placeCell {
      width: 40%;
    }
  }
}

.markerclusterMap {
  width: 100%;
  height: 330px;
  border-radius: 12px;
}

.tzSelector {
  z-index: 10px;
  position: absolute;
  right: -5%;
}

.tzContainer {
  text-align: center;
  font-size: 0.9em;
  font-weight: 500;
  color: #949AA2;
  position: relative;
  background-color: #F2F2F2;
  height: 2em;
  margin: auto;
  margin-top: 0.4em;
  padding-top: 1%;
  padding-left: 10px;
  padding-right: 10px;
}

.clockTz {
  color: #0D0C0D;
}

.buttonDiv {
  margin: 15px;
  text-align: center;
}

.checkInButton {
  @include button();
}

.checkOutButton {
  @include secondaryButton();
}

.button {
  border-radius: 20px !important;
  padding: 13px 22px !important;
  font-weight: 700;
  width: 150px;
}

.text {
  color: white;
}

#attendanceErrorMessage {
  min-height: unset;
}
