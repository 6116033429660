.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  text-align: center;
  margin-top: 0.5em;
  font-size: 1em;
  color: #909090;
  position: relative;

  &::before {
    content: '';
    border: 1px solid #8f8f8f;
    height: 0px;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: -1;
  }

  time {
    background: #fff;
    padding: 0 1em;
    font-weight: bold;
  }
}
