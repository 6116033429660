.item {
  display: flex;
  align-items: center;

  .content {
    margin-left: 0.5em;

    .email {
      font-size: 0.8em;
    }
  }
}
