@import 'generalStyle';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .title {
    font-size: 1em;
    color: #ff6e79;
    font-weight: bold;
    margin-top: 1em;
  }

  .form {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    margin-top: 1em;
  }

  .project {
    padding-top: 10px;
  }

  .categoryTime {
    display: flex;
    flex-direction: row;

    .category {
      width: 60%;
    }

    .durationHours {
      margin-left: auto !important;
      width: 18%;
    }

    .durationSeparator {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }

    .durationMinutes {
      width: 18%;
    }

  }

  .commentClassName {
    margin-top: 10px !important;
  }
}

.button {
  background-color: $primarySelectedRowColor !important;
  color: #fff !important;
  font-family: inherit !important;
}
