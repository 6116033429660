@import 'generalStyle';

.dropdown,
.dropdown:hover {
  border: 1px solid $secondaryGreenColor !important;
  color: $fourthGrayColor !important;
  background-color: transparent !important;
  font-weight: normal;

  // stylelint-disable-next-line selector-no-qualifying-type
  div[role='alert'] {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 90%;
  }
}
