@import 'generalStyle';

.generalContainer {
  height: 100%;
  width: 100%;
}

.noDataContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.attendance {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.attendanceContent {
  width: calc(100% - 4px);
  height: calc(100% - 48px);
  overflow-y: auto;
  overflow-x: auto;
  margin-bottom: 8px;
}

.attendancePagination {
  width: 100%;
  height: 40px;
}

.attendanceTable {
  width: 100%;
  background-color: transparent !important;
  border: 0px !important;
}

.attendanceItemList {
  height: 80px;
}

.emptyMessage {
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100% !important;
  height: 100% !important;
}
