@import 'generalStyle';

.layoutWebCmpt {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: $primaryBackgroundColor;
}

.sidebar {
  width: 324px;
  height: 100%;
}

.container {
  width: calc(100% - 332px);
  height: calc(100% - 16px);
  margin: 8px 8px 8px 0px !important;
}

.header {
  width: 100%;
  height: 64px;
}

.content {
  width: 100%;
  height: calc(100% - 64px);
}

// HOME VIEW

.homeLayoutWebCmpt {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: $primaryBackgroundColor;
}

.homeSidebar {
  position: absolute;
  z-index: 500;
  width: 324px;
  height: 100%;
}

.homeContainer {
  width: 100%;
  z-index: 1;
  position: absolute;
  height: 100%;
}

.homeHeader {
  position: absolute;
  z-index: 500;
  transform: translateX(308px);
  width: calc(100% - 332px);
  height: 64px;
  margin: 8px 16px 0px;
}

.homeContent {
  position: absolute;
  width: 100%;
  height: 100%;
}
