@import 'generalStyle';

article {
  font-size: 13px;
  color: #444;

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      background: none;
      border: 0;
    }
  }
}

.info,
.description {
  background-color: #f5f5f5;
  border-radius: 0.8em;
  padding: 1em;
  margin-top: 0.5em;
  white-space: pre-line;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .project span {
    font-weight: bold;
  }

  .categoryDuration {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .category span,
    .duration span {
      font-weight: bold;
    }
  }

  .duration > * {
    vertical-align: middle;
  }

  .icon {
    color: #8f8f8f;
    margin-right: 0.5em;
  }
}

.avatar {
  display: flex;
  flex-direction: row;

  .avatar-info {
    font-size: 0.9em;
    text-align: left;
    margin-left: 10px;
  }

  .avatar-info h3 {
    font-size: inherit;
    margin: 0;
  }
}

.buttonContainer {
  margin-top: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  background-color: $primarySelectedRowColor !important;
  color: #fff !important;
}
