@import 'generalStyle';

.accordion {
  width: 100%;
  border-left: solid 4px $thridGrayColor;
  background-color: $defaultBackgroundColor;
  padding: 4px 16px !important;
  border-radius: 8px;
  box-shadow: 1px 4px 10px $secondaryShadowColor;
  margin: 8px 0;

  button {
    background-color: $primaryGreenColor !important;
    color: #fff !important;
  }
}

.rotate {
  transform: rotate(45deg);
}

.accordionTitle {
  color: $primaryGreenColor !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plusIcon {
  margin-right: 8px;
  transition-duration: 250ms;
}
