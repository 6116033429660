@import 'generalStyle';

.dateRangePickerButton {
  justify-content: space-evenly;
  align-items: center;
  height: 40px;
  width: 235px;
  background-color: transparent;
  text-align: center;
  border-radius: 4px;
  border: 1px solid $secondaryGreenColor;
  color: $fourthGrayColor;
  outline: none;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
}

.dateRange {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1218px) {
  .dateRangePickerButton {
    width: 170px;
  }
}

@media only screen and (max-width: 768px) {
  .dateRangePickerButton {
    width: 200px;
  }
}

.calendarTrigger {
  color: $secondaryGreenColor;
  cursor: pointer;
}

.dateRangePickerContent .rdrMonthName {
  display: none;
}

.dateRangePickerContent .rdrDefinedRangesWrapper {
  width: 120px;
}

.dateRangePickerContent .rdrDefinedRangesWrapper .rdrStaticRangeLabel {
  color: $primaryDatePickerColor !important;
  font-weight: initial;
}

.dateRangePickerContent .rdrMonth {
  padding: 0;
}

.dateRangePickerContent .rdrDateDisplayWrapper {
  background-color: #fff;
}

.dateRangePickerContent .rdrDays .rdrDayNumber {
  font-weight: initial;
}

.dateRangePickerContent .rdrMonthAndYearPickers {
  color: $primaryDatePickerColor !important;
  font-weight: initial;
}

.dateRangePickerContent .rdrNextPrevButton {
  background: transparent;
}

.dateRangePickerContent.mobile .rdrDefinedRangesWrapper {
  display: none;
}

.dateRangePickerContent.mobile .rdrDateDisplayWrapper {
  display: none;
}
