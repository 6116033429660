@import './themes/default/';

/* stylelint-disable selector-max-id */
#root {
  width: 100%;
  height: 100%;
}

:root {
  --toastify-toast-width: 320px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  scrollbar-width: thin;
  scrollbar-color: #0006 #b6b6b6;
}

.leaflet-touch .leaflet-bar {
  display: none;
}

.autoDots {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 1.3;
}

::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar-track {
  background-color: #0000004d !important;
}

::-webkit-scrollbar-thumb {
  background-color: #585858 !important;
}

.ui.selectable.table {
  tbody {
    tr {
      &:hover {
        background-color: $primaryHoveredRowColor !important;
      }
    }
  }
}

.table tr {
  td {
    border-top: 0px !important;
  }
}

input,
button,
textarea {
  font-family: 'Roboto', sans-serif !important;
}

.loadMore {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  button {
    font-size: 12px !important;
    font-weight: bold !important;
    box-shadow: none !important;
  }

  button:hover {
    box-shadow: 0 0 2px $primaryGrayColor inset, 0 0 0 0 $thridGrayColor inset !important;
  }
}
