@import 'generalStyle';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .body {
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
    margin: 1em 0;
    position: relative;

    .addRecordButton {
      background: $primarySelectedRowColor;
      border: 0;
      color: #fff;
      position: absolute;
      border-radius: 32px;
      text-transform: uppercase;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.activities-list {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  padding: 0 1em;

  > li {
    margin-bottom: 3em;
  }
}

.activities-list-empty-item {
  text-align: center;
}
