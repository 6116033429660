@import '../../../generalStyle';

$sideBarTitleColor: #ff6b76;
$tabPickerLinkRadius: 14px;

.container {
  display: flex;
}

.sidebar,
.tabPicker {
  @include shadow();
}


.tabPicker {
  border: 0px !important;
  margin: 10px !important;
  border-radius: $tabPickerLinkRadius !important;
  z-index: 3;

  > *:first-child {
    border-radius: $tabPickerLinkRadius 0 0 $tabPickerLinkRadius !important;
  }

  > *:last-child {
    border-radius: 0 $tabPickerLinkRadius $tabPickerLinkRadius 0 !important;

    &::before {
      background: none !important;
    }
  }
}

.contentTab {
  font-size: 0.9em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.menuLabel {
  margin-left: 0.8em;
}

.hideMenuLabel {
  display: none;
}

.selectedTab {
  color: $primaryGreenColor !important;
  height: 40px;
}


.tabItem {
  color: $thridGrayColor !important;
  height: 40px;
}

.homeRadius {
  border-top-left-radius: $tabPickerLinkRadius;
  border-bottom-left-radius: $tabPickerLinkRadius;
}

.timesheetRaduis {
  border-top-right-radius: $tabPickerLinkRadius;
  border-bottom-right-radius: $tabPickerLinkRadius;
}
