@import 'generalStyle';

.marker-group {
  background: #00acfb;
  border: 2px solid #fff;
  box-shadow: 1px 2px 2px $primaryShadowColor;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  line-height: 39px;
  text-align: center;
  font-size: 18px;
}

.mapSize {
  height: 100%;
  width: 100%;
}
