/* Background colors */
$primaryBackgroundColor: #f1f3f3;

/* Availabity and tab colors */
$primaryGreenColor: #3fc766;
$secondaryGreenColor: #6de48e;

/* Unavailabity, table lines, icons and text colors*/
$primaryGrayColor: #000000e6;
$secondaryGrayColor: #000;
$thridGrayColor: #0000004d;
$fourthGrayColor: #707070;
$fifthGrayColor: #525252;
$sixthGrayColor: #0000001a;
$seventhGrayColor: #00000074;

/** Table row selection **/
$primarySelectedRowColor: #00b1ff;
$primarySelectedRowTextColor: #fff;

/** Default background color **/
$defaultBackgroundColor: #fff;

/** Table row hover **/
$primaryHoveredRowColor: #00000008;

/** Standard button colors **/
$primaryBackGroundButtonColor: #6de48e;
$primaryTextButtonColor: #fff;
$secondaryBackGroundButtonColor: #7ba8d4;
$secondaryTextButtonColor: #fff;

/** Shadows color **/
$primaryShadowColor: #0000004d;
$secondaryShadowColor: #00000026;
$thirdShadowColor: #0000001a;
$fourthShadowColor: #000c;

/** Shadows dimentions **/
$primaryCardShadowDimensions: 1px 1px 25px -11px;

/** Shadows dimentions **/
$primaryDatePickerColor: #1d2429;

/** Inputs color **/
$primaryInputFocusColor: #85b7d9;
