@import 'generalStyle';

.sidebarWebCmpt {
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  display: flex;
  flex-wrap: wrap;
  margin: 16px;
  background-color: $defaultBackgroundColor;
  border-radius: 12px;
}

.header {
  width: 100%;
  height: 64px;
}

.usersHeader {
  width: 100%;
  height: 108px;
}

.content {
  width: 100%;
  height: calc(100% - 96px);
}

.withoutLogo {
  height: calc(100% - 108px) !important;
  width: 100%;
}

.footer {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ponctuelLogo {
  width: 40%;
}

.sidebarLoading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
