@import 'generalStyle';

.connectionHour {
  color: $fourthGrayColor;
  font-size: 16px;
}

.connectionHour::first-letter {
  text-transform: capitalize;
}

.userInfo {
  display: flex;
  flex-direction: row;
  vertical-align: top;
  margin-top: 16px;
}

.userName {
  color: $fifthGrayColor;
  font-size: 18px;
  margin-top: 16px;
  margin-bottom: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  width: 245px;
}

.userStateInfo {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  padding: 5px;
}

.userState {
  display: flex;
  align-items: center;
}

.placeholder {
  min-width: 245px;
}
