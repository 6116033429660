@import '../../generalStyle';

.container {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  background-color: $primaryBackgroundColor;
}

