.workspaceGrid.ui.grid {
  margin: 0px !important;

  .ix-survey-workplace-cmpt {
    position: absolute;
    top: -64px;
  }
}

.ui.container {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

.ui.fluid.container {
  position: relative;

  .popup-button {
    position: absolute;
    top: -65px;
    right: 7%;

    .ui.teal.button {
      padding-right: 10px;
    }
  }
}
