/* stylelint-disable max-nesting-depth */
/* stylelint-disable declaration-property-value-disallowed-list */
@import 'generalStyle';

@mixin icon {
  position: absolute;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
  transform: translate(-50%, 0%);
  left: 70%;
  top: 50%;
}

.availableAvatar {
  display: inline-block;
  width: 3.5em;
  height: 3.5em;
  border-radius: 500rem;
  border: 3px solid $primaryGreenColor;
}

.notAvailableAvatar {
  border: 3px solid $fifthGrayColor;
}

.available {
  letter-spacing: 1px;
  color: $primaryGreenColor;
  font-weight: bold;
  font-size: 14px;
}

.notAvailable {
  color: $fifthGrayColor;
}

.profileImageAtt {
  display: flex;
  justify-content: center;
}

.profileImageSid {
  padding-left: 14px !important;
}

.profileEventTime {
  color: $thridGrayColor;
  font-size: 12px;
  text-align: right !important;
  float: right;
}

.memberTableTr {
  &:hover {
    .cell {
      &.pressable {
        .iconContainer {
          transform: translate(-50%, -50%);
          opacity: 1;
        }

        .downloadIcon {         
          transform: translate(-50%, -50%);
          opacity: 1;
        }
      }
    }
  }
}

.memberTableTr:hover {
  background-color: $primaryHoveredRowColor;
}

.disabledMemberTableTr {
  background-color: $primaryHoveredRowColor;
}

.userTableTrSel {
  background-color: $primarySelectedRowColor !important;
  color: $primarySelectedRowTextColor !important;
}

.nopadding {
  padding: 0px !important;
  border: 0px solid !important;
}

.status {
  padding-top: 5px !important;
}

.profileDataTableColor {
  background-color: transparent !important;
}

.userInformationCell {
  height: 100%;
  max-width: 312px;
  min-width: 300px;
  border-bottom: 1px solid $sixthGrayColor !important;
  padding: 8px 8px 8px 16px !important;
}

.cell {
  font-size: 12px;
  color: $secondaryGrayColor;
  border-bottom: 1px solid $sixthGrayColor !important;
  position: relative;
  padding: 8px !important;

  .iconContainer {
    position: absolute;
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0;
    transform: translate(-50%, 0%);
    width: 100%;
    left: 50%;
    top: 50%;

    .plusIcon {
      display: inline;
      opacity: 0;
      color: $thridGrayColor;
      margin-right: 2px;
      margin-left: 2px;
      cursor: pointer;
    }

    .downloadDailyIcon {
      opacity: 0;
      color: $primaryGreenColor;
      margin-left: 2px;
      margin-right: 2px;
      cursor: pointer;
    }

    .detailIcon {
      opacity: 0;
      color: $thridGrayColor;
      cursor: pointer;
    }

    .downloadIcon {
      @include icon();
      cursor: pointer;
      opacity: 0;
      color: $primaryGreenColor;
    }
  }

  &.pressable:hover {
    .iconContainer {
      transform: translate(-50%, -50%);
      opacity: 1;
    }

    .plusIcon {
      opacity: 1;
    }

    .detailIcon {
      opacity: 1;
    }

    .downloadDailyIcon {
      opacity: 1;
    }

    .label {
      display: none;
    }
  }
}

.removeUserStyles {
  display: flex;
  width: 100%;
  border: none;
}

.removeUserStyles:hover {
  background-color: transparent !important;
}

.reportContainer {
  display: inline-block;
}
