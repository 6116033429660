@import 'generalStyle';

.checkInAvatar {
  border-radius: 50%;
  border: 3px solid $primaryGreenColor;
}

.checkOutAvatar {
  border-radius: 50%;
  border: 3px solid $fifthGrayColor;
}

.checkInCircleAvatar {
  fill: #ACD3DE;
  stroke-width: 3;
  stroke: $primaryGreenColor;
}

.checkOutCircleAvatar {
  fill: #ACD3DE;
  stroke-width: 3;
  stroke: $fifthGrayColor;
}

.textAvatar {
  fill: #FFFFFF;
  font-weight: normal;
  text-anchor: middle;
  dominant-baseline: central;
}

.avatarItem {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.circleAvatarItem {
  fill: #ACD3DE;
  r: 14px;
}
