@import 'generalStyle';

.button {
  background-color: $primarySelectedRowColor !important;
  color: #fff !important;
}

.buttonContainer {
  margin-top: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}
