@import 'generalStyle';

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  max-height: 645px;

  .title {
    font-size: 1em;
    color: #ff6e79;
    font-weight: bold;
    margin-top: 1em;
  }

  .errorMessage {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .form {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    padding: 16px;
  }
  
  .control {
    margin-bottom: 30px !important;
  }
}

.radio {
  margin-right: 20px;
}

.header {
  font-size: 1.2em;
  color: #ff6e79;
  font-weight: bold;
  margin: 1em 0;
}

.button {
  background-color: $primarySelectedRowColor !important;
  color: #fff !important;
  margin-top: 2em !important;
  font-family: inherit !important;
}

.displayCustomVisibility {
  display: block;
}

.hideCustomVisibility {
  display: none;
}
