@import 'generalStyle';

$sideBarTitleColor: #ff6b76;
$tabPickerLinkRadius: 14px;

.groupItemCmpt {
  width: calc(100% - 32px);
  padding: 10px 0px 10px 12px;
  margin: 0px 16px;
  cursor: pointer;
  height: 44px;
  display: flex;
}

.groupsIcon {
  @include icon($seventhGrayColor, 15px, 3px, 0px, relative);
  margin-right: $tabPickerLinkRadius !important;
}

.groupText {
  @include title(14px, $primaryGrayColor);
  width: calc(100% - 20px);
}

.groupTextIcon {
  @include icon($seventhGrayColor, 15px, 3px, 0px, relative);
  margin-right: $tabPickerLinkRadius !important;
}

.singleGroupActions {
  display: none !important;
}

.groupItemCmpt:hover .singleGroupActions {
  display: block !important;
}

.groupItemCmpt:hover {
  background-color: $primaryHoveredRowColor;
  border-radius: 8px;
}

.favoriteGroupIcon {
  display: block !important;
}

.groupItemCmpt:hover .favoriteGroupIcon {
  display: none !important;
}

.activeGroup {
  background-color: $primarySelectedRowColor !important;
  border-radius: 8px;

  .groupsIcon {
    color: $primarySelectedRowTextColor !important;
  }

  .singleGroupActions {
    color: $primarySelectedRowTextColor !important;
  }

  .groupText {
    color: $primarySelectedRowTextColor !important;
  }
}

.hoverGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
}

.hoverGroupName {
  width: max-content;
}
