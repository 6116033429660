.container {
  display: flex;
}

.projectFilter {
  margin-left: 10px !important;
  margin-right: 5px;
}

.dateRange {
  width: 40px;
  min-width: 40px;
  padding: 5px !important;
  background-color: #fff !important;
  border-radius: 10px !important;
  margin-right: 5px !important;
  height: 40px !important;
  max-height: 40px !important;
}

.exportButton {
  margin-left: auto !important;
  padding: 10px !important;
  width: 90px;
  margin-right: 5px !important;
  min-width: 90px;
  border-radius: 20px !important;
  height: 40px !important;
  max-height: 40px !important;
}
