.termsAndConditions {
  .content {
    width: 100%;
    padding: 6%;
    height: 100%;
    overflow-y: auto;

    .titleContent {
      font-size: 18px !important;
      color: #28e05b !important;
      max-width: 1000px;
      margin: 0 auto !important;
      float: none !important;
      padding-bottom: 14px;
    }

    .subtitleContent {
      font-size: 16px !important;
      max-width: 1000px;
      color: #28e05b !important;
      margin: 0 auto !important;
      float: none !important;
      padding-top: 25px;
      padding-bottom: 14px;
    }

    .textContent {
      font-size: 14px !important;
      color: #606060 !important;
      text-align: justify;
      margin: 0 auto !important;
      float: none !important;
      max-width: 1000px;
      padding-bottom: 14px;
    }

    .ui.bulleted.list {
      max-width: 1000px;
      margin: 0 auto !important;
      float: none !important;
      padding-left: 3rem !important;
    }
  }
}
