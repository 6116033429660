@import 'generalStyle';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
}

// TODO: Use variables in src/themes/default/variables.scss

.modal {
  @include shadow();
  background: #fff;
  border-radius: 12px;
  display: inline-block;
  padding: 16px;
  position: relative;
  width: 90%;
  max-width: 480px;
  height: 90%;
  max-height: 720px;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;

  * {
    outline: none;
    font-family: 'Roboto', sans-serif;
  }

  .close {
    border: 0;
    background: none;
    color: #e0e0e0;
    right: 1em;
    top: 1em;
    position: absolute;
    cursor: pointer;
    z-index: 999;

    &:hover {
      color: #909090;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  .header {
    display:flex;
    justify-content: center;
    text-align: center;
    font-size: 1.25em;
    color: #ff6e79;
    font-weight: bold;
    margin: 0.67em 0;
  }

  .body {
    overflow: auto;
    list-style: none;
    flex-grow: 1;
    height: 100%;
  }
}

@media screen and (max-height: 650px) {
  .modal {
    top: 50%;
    left: 50%;
    bottom: -40%;
  }
}
