@import '../../../generalStyle';

@mixin user-status {
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 14px;
  margin-top: 5px;
}

.container {
  border-bottom: 1px solid $sixthGrayColor !important;
}

.userInfoContainer {
  display: flex;
  width: 100%;
  cursor: pointer;
  padding-top: 7px;
  padding-bottom: 7px;
}

.userSelected {
  background-color: $primarySelectedRowColor !important;
  color: $primarySelectedRowTextColor !important;

  .userInfoEmail {
    color: $primarySelectedRowTextColor !important;
  }
}

.available {
  @include user-status();
  color: $primaryGreenColor;
}

.notAvailable {
  @include user-status();
  color: $fifthGrayColor;
}

.userImage {
  padding: 10px;
}

.userInfo {
  width: calc(100% - 150px);
  display: flex;
  flex-wrap: wrap;
  align-content: center;

  .userInfoName {
    width: 100%;
    font-size: 1.1em;
  }

  .userInfoEmail {
    width: 100%;
    font-size: 0.9em;
    color: $thridGrayColor;
    font-weight: bold;
  }
}

.userTotalHours {
  display: flex;
  width: 80px;

  span {
    font-size: 1.1em;
  }

  svg {
    margin-left: auto;
  }
}

.userStatus {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.lastUserConnection {
  color: rgba(0, 0, 0, 0.301961);
  font-size: 12px;
  margin-top: auto;
  margin-left: auto;
  margin-bottom: 10px;
  margin-right: 10px;
}

.detailContainer {
  display: flex;
  width: 100%;

  .detailReport {
    width: 70px;
    justify-content: center;
    text-align: center;

    .downloadIcon {
      color: $primaryGreenColor;
    }
  }

  .detailList {
    list-style: none;
    width: calc(100% - 70px);
  }

  .detailListItem {
    display: flex;
    align-items: center;
    margin: 5px;

    .detailListItemDate {
      @include title(14px, $thridGrayColor);
      width: 40%;
    }

    .detailListItemHours {
      margin-left: 20px;
      width: 30%;
    }

    &.available {
      color: $primaryGreenColor;

      .detailListItemDate {
        color: $primaryGreenColor !important;
      }
    }
  }

  .detailListItemActions {
    margin-left: auto;
    margin-right: 20px;
    width: 30%;

    .detailListItemDetailIcon {
      color: $thridGrayColor;
      margin-right: 2px;
      margin-left: 2px;
    }

    .detailListItemPlusIcon {
      color: $thridGrayColor;
      margin-right: 2px;
      margin-left: 2px;
    }

    .detailListItemReportIcon {
      color: $primaryGreenColor;
      margin-left: 2px;
      margin-right: 2px;
    }
  }
}
